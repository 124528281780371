// i18next-extract-mark-ns-start home

import React, {useEffect, useRef} from "react";
import Layout from "src/components/layout";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'src/../tailwind.config'
import sideMenuStore from "src/components/side-menu/side-menu-store"

import svgSrcMobile from 'src/images/SVG/Simplified_Laws_of_the_Game.svg'
import svgSrcDesktop from 'src/images/SVG/Simplified_Laws_of_the_Game_Desktop.svg'

const tailwind = resolveConfig(tailwindConfig)

const IndexPage = ({ data }) => {
  const setIsSideMenuOpen = sideMenuStore((state) => state.setIsSideMenuOpen);
  const { title, description } = data.homePage.seo;

  const bgWrapper = useRef<HTMLDivElement>(null);
  const scalableButton = useRef<HTMLButtonElement>(null);
  const svgTextM = useRef<HTMLImageElement>(null);
  const svgTextD = useRef<HTMLImageElement>(null);

  const updateDynamicLayoutValues = () => {
    let scale = 1;

    const bgW = 1920;
    const bgH = 934;
    const textH = 250.625;
    
    const bgMW = 900;
    const bgMH = 1428;
    const textMW = 262.984*3;

    let wrapperH = bgWrapper.current.offsetHeight;
    let wrapperW = bgWrapper.current.offsetWidth;

    if(window.innerWidth >= parseInt(tailwind.theme.screens.md)){
      // desktop
      if(wrapperW / wrapperH <= bgW / bgH){
        scale =  ( textH * (wrapperH/bgH)) / textH;
      }
      else{
        scale =  ((wrapperW*(bgH/bgW))/3.727) / textH;
      }
      
      svgTextD.current.style.marginRight = wrapperH/wrapperW > .9 ? '-5rem' : null;
      scalableButton.current.style.transform = 'scale('+scale+') translateY(3.75rem)';
    }
    else{
      // mobile

      if(wrapperW / wrapperH <= bgMW / bgMH){
        scale =  ((wrapperW*(bgMH/bgMW))/0.59) / textMW;
      }
      else{
        scale =  (( textMW * (wrapperW/bgMW)) / textMW)*3.06;
      }

      svgTextM.current.style.visibility = wrapperH/wrapperW < 1 ? 'hidden' : null;
      scalableButton.current.style.transform = 'scale('+scale+') translateY(2.82rem)';
    }

  };
  
  useEffect(() => {
    updateDynamicLayoutValues();
  
    window.addEventListener("resize", updateDynamicLayoutValues);
  
    return () => {
      window.removeEventListener("resize", updateDynamicLayoutValues);
    };
  });

  useEffect(() => {
    const onPageLoad = () => {
      scalableButton.current.style.visibility = 'visible';
      scalableButton.current.style.opacity = '1';
    };
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Layout featuredNewsItem={data.featuredNewsItem?.newsItem}>
        <div ref={bgWrapper}
          className="relative bg-main-page-mobile md:bg-main-page bg-almostBlackBlue bg-center bg-no-repeat bg-cover flex flex-col justify-evenly items-center min-h-[87vh] md:min-h-[70vh] xl:min-h-[calc(100vh-6.625rem)] p-0"
        >
          <button
            ref={scalableButton}
            type="button"
            onClick={() => setIsSideMenuOpen(true)}
            className="ease-out duration-500 invisible opacity-0 mx-auto tracking-wide"
          >
            <h1 className="text-[3.3rem] md:text-[9rem] leading-[.88] text-center flex flex-col justify-center text-appGreen font-black italic uppercase">
              <span className="block">Football</span> 
              <span className="block">rules</span>
              <img ref={svgTextM} className="w-[9.4rem] mx-auto mt-14 block md:hidden" src={svgSrcMobile} alt="Simplified laws of the Game" />
              <img ref={svgTextD} className="w-[27.5rem] mx-auto md:mr-[-7.35rem] md:-mt-2 hidden md:block z-10" src={svgSrcDesktop} alt="Simplified laws of the Game" />
            </h1>
          </button>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    featuredNewsItem: datoCmsFeaturedNewsItem(locale: { eq: $language }) {
      newsItem {
        title
        slug
        date
      }
    }
    homePage: datoCmsFootballrulesHomePage(locale: { eq: $language }) {
      seo {
        title
        description
      }
    }
  }
`;

export default IndexPage;
